import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '/src/components/layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk, faMonument, faUserGear } from '@fortawesome/free-solid-svg-icons'

const OmHistoriaPage = () => {
	return (
		<Layout pageTitle="Brfifys historia">
			<p>XXX</p>
			
			<p>XXX</p>
		</Layout>
	)
}

export default OmHistoriaPage